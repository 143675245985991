import { Inject, Injectable, OnDestroy } from '@angular/core';
import { Observable, BehaviorSubject, of, Subscription } from 'rxjs';
import { map, catchError, switchMap, finalize } from 'rxjs/operators';
import { AuthModel } from '../models/auth.model';
import { AuthHTTPService } from './auth-http';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { ClientDto } from 'src/app/domains/dto/client.dto';
import { IResponse } from 'src/app/base/base.interface';

export type UserType = AuthModel | undefined;

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {
  // private fields
  private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/
  private authLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`;
  private authLocalStorageData = 'USER';

  // public fields
  currentUser$: Observable<UserType>;
  isLoading$: Observable<boolean>;
  currentUserSubject: BehaviorSubject<UserType>;
  isLoadingSubject: BehaviorSubject<boolean>;
  get currentUserValue(): UserType {
    return this.currentUserSubject.value;
  }

  set currentUserValue(user: UserType) {
    this.currentUserSubject.next(user);
  }

  constructor(
    private router: Router,
    private authHttpService: AuthHTTPService
  ) {
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    this.currentUserSubject = new BehaviorSubject<UserType>(undefined);
    this.currentUser$ = this.currentUserSubject.asObservable();
    this.isLoading$ = this.isLoadingSubject.asObservable();
  }

  // public methods
  login(email: string, password: string): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.authHttpService.login(email, password).pipe(
      map((auth: IResponse<AuthModel>) => {
        const result = this.setAuthFromLocalStorage(auth.data);
        return result;
      }),
      switchMap(() => this.getUserByToken()),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  logout() {
    localStorage.removeItem(this.authLocalStorageToken);
    localStorage.removeItem(this.authLocalStorageData);
    this.router.navigate(['/admin/auth/login'], {
      queryParams: {},
    });
  }

  getUserByToken(): Observable<IResponse<ClientDto>> {
    const authData = this.getAuthDataFromLocalStorage();
    if (authData) {
      this.currentUserSubject.next(authData);
      return of();
    }
    const auth = this.getAuthFromLocalStorage();
    if (!auth) {
      return of();
    }

    this.isLoadingSubject.next(true);
    return this.authHttpService.getUserByToken().pipe(
      map(user => {
        if (user && user.status === 401) {
          this.logout();
        }
        this.currentUserSubject.next(user?.data as unknown as AuthModel);
        this.setAuthDataFromLocalStorage(user?.data as unknown as AuthModel);
        return user;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  // need create new user then login
  registration(user: AuthModel): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.authHttpService
      .createUser(user)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }

  forgotPassword(email: string): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.authHttpService
      .forgotPassword(email)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }

  verifyCode(email: string, code: string): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.authHttpService
      .verifyCode(email, code)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }

  updateForgotPassword(dto: ClientDto): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.authHttpService
      .updateForgotPassword(dto)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }

  updatePassword(dto: ClientDto): Observable<any> {
    const auth = this.getAuthFromLocalStorage();
    if (!auth) {
      return of();
    }
    this.isLoadingSubject.next(true);
    return this.authHttpService
      .updatePassword(dto)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }

  updateEmail(dto: ClientDto): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.authHttpService
      .updateEmail(dto)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }

  updateProfile(dto: ClientDto): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.authHttpService
      .updateProfile(dto)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }

  // private methods
  private setAuthFromLocalStorage(auth: AuthModel): boolean {
    // store auth authToken/refreshToken/epiresIn in local storage to keep user logged in between page refreshes
    if (auth && auth.accessToken) {
      localStorage.setItem(
        this.authLocalStorageToken,
        JSON.stringify(auth?.accessToken)
      );
      return true;
    }
    return false;
  }

  getAuthFromLocalStorage(): string | undefined {
    try {
      const lsValue = localStorage.getItem(this.authLocalStorageToken);
      if (!lsValue) {
        return undefined;
      }

      const authData = JSON.parse(lsValue);
      return authData;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  // private methods
  private setAuthDataFromLocalStorage(auth: AuthModel): boolean {
    // store auth authToken/refreshToken/epiresIn in local storage to keep user logged in between page refreshes
    if (auth) {
      localStorage.setItem(this.authLocalStorageData, JSON.stringify(auth));
      return true;
    }
    return false;
  }

  getAuthDataFromLocalStorage(): AuthModel | undefined {
    try {
      const lsValue = localStorage.getItem(this.authLocalStorageData);
      if (!lsValue) {
        return undefined;
      }

      const authData = JSON.parse(lsValue);
      return authData;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  getAuthFromLocalStorageAsObservable(): Observable<AuthModel | undefined> {
    try {
      const lsValue = localStorage.getItem(this.authLocalStorageData);
      if (!lsValue) {
        return of(undefined);
      }

      const authData = JSON.parse(lsValue);
      this.currentUserSubject.next(authData as AuthModel);
      return of(authData).pipe(
        finalize(() => this.isLoadingSubject.next(false))
      );
    } catch (error) {
      console.error(error);
      return of(undefined);
    }
  }

  ngOnDestroy() {
    this.unsubscribe.forEach(sb => sb.unsubscribe());
  }
}
