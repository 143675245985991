import { ContentChild, Injectable, TemplateRef } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment';
import { ToastService } from 'src/app/_metronic/shared/components/toast-global/toast.service';
const API_URL = `${environment.apiUrl}`;

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  dangerTpl = `<ng-template #dangerTpl>
  <!--begin::Toast-->
  <div class="position-fixed top-0 end-0 p-3 z-index-3">
    <div
      id="kt_docs_toast_toggle"
      class="toast"
      role="alert"
      aria-live="assertive"
      aria-atomic="true">
      <div class="toast-header">
        <i class="ki-duotone ki-abstract-19 fs-2 text-danger me-3"
          ><span class="path1"></span><span class="path2"></span
        ></i>
        <strong class="me-auto">Keenthemes</strong>
        <small>11 mins ago</small>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="toast"
          aria-label="Close"></button>
      </div>
      <div class="toast-body">Hello, world! This is a toast message.</div>
    </div>
  </div>
  <!--end::Toast-->
</ng-template>`;

  constructor(
    private authService: AuthService,
    private toastService: ToastService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const auth = this.authService.getAuthFromLocalStorage();
    const isLoggedIn = auth ? true : false;
    const isApiUrl = request.url.startsWith(API_URL);
    if (isLoggedIn && isApiUrl) {
      request = request.clone({
        setHeaders: {
          Authorization: `${auth}`,
          'System-Secret-Key': environment.secretKey,
          DeviceKey: environment.deviceKey,
          DeviceUuid: environment.deviceUuid,
          'x-lang': environment.xLang,
        },
      });
    } else {
      request = request.clone({
        setHeaders: {
          DeviceKey: environment.deviceKey,
          DeviceUuid: environment.deviceUuid,
          'x-lang': environment.xLang,
        },
      });
    }

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        let errorMsg = '';
        if (error.error instanceof ErrorEvent) {
          errorMsg = `Error: ${error.error.message}`;
        } else {
          errorMsg = `Error Code: ${error.status},  Message: ${error.message}`;
        }
        console.log(errorMsg);
        this.toastService.show({
          template: this.dangerTpl as unknown as TemplateRef<any>,
          classname: 'bg-danger text-light',
          delay: 2000,
        });
        return throwError(errorMsg);
      })
    );
  }
}
