import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { AuthModel } from '../../models/auth.model';
import { ClientDto } from 'src/app/domains/dto/client.dto';
import { IResponse } from 'src/app/base/base.interface';

const API_USERS_URL = `${environment.apiUrl}/auth`;

@Injectable({
  providedIn: 'root',
})
export class AuthHTTPService {
  constructor(private http: HttpClient) {}

  // public methods
  login(email: string, password: string): Observable<any> {
    return this.http.post<AuthModel>(`${API_USERS_URL}/login`, {
      username: email,
      password,
    });
  }

  // CREATE =>  POST: add a new user to the server
  createUser(user: AuthModel): Observable<AuthModel> {
    return this.http.post<AuthModel>(`${API_USERS_URL}/create`, {
      model: user,
    });
  }

  // Your server should check email => If email exists send link to the user and return true | If email doesn't exist return false
  forgotPassword(email: string): Observable<any> {
    return this.http.post<any>(`${API_USERS_URL}/forgotPassword`, {
      email,
    });
  }

  verifyCode(email: string, code: string): Observable<any> {
    return this.http.post<any>(`${API_USERS_URL}/verifyCode`, {
      email,
      code,
    });
  }

  updateForgotPassword(dto: ClientDto): Observable<any> {
    return this.http.post<any>(`${API_USERS_URL}/updateForgotPassword`, dto);
  }

  updatePassword(dto: ClientDto): Observable<any> {
    return this.http.post<any>(`${API_USERS_URL}/changePassword`, dto, {});
  }

  updateEmail(dto: ClientDto): Observable<any> {
    return this.http.post<any>(`${API_USERS_URL}/changeEmail`, dto, {});
  }

  updateProfile(dto: ClientDto): Observable<any> {
    return this.http.post<any>(`${API_USERS_URL}/updateProfile`, dto, {});
  }

  getUserByToken(): Observable<IResponse<ClientDto>> {
    return this.http.get<IResponse<ClientDto>>(`${API_USERS_URL}/profile`, {});
  }
}
