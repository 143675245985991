// Japan
export const locale = {
  lang: 'ja',
  data: {
    LANGUAGES: '言語',
    COMMON: {
      BUTTON: {
        ACTION: '操作',
        SEARCH: '検索',
        WAIT: '検索中...',
        DELETE: '削除',
        HIRE: '採用',
        EXPORT: '出力',
        CLOSE: 'キャンセル',
        CONTINUE: '続く',
        SUBMIT: '送信',
        LOGOUT: 'サインアウト',
      },
      TOOLTIP: {
        CREATE: 'デバイスの詳細',
        DELETE: '削除',
        LIST: '時計',
        CHANGE_PASSWORD: 'パスワードを変更する',
      },
      TITLE: {
        ADVANCE_SEARCH_AREA: '詳細検索エリア',
        SORT_AREA: '並べ替えエリア',
      },
    },
    TRANSLATOR: {
      SELECT: 'あなたが使う言語を選んでください',
    },
    MENU: {
      NEW: '新しい',
      ACTIONS: '行動',
      CREATE_POST: '新しい投稿を作成',
      PAGES: 'Pages',
      FEATURES: '特徴',
      APPS: 'アプリ',
      DASHBOARD: 'ダッシュボード',
      LAYOUT_BUILDER: 'レイアウトビルダー',
      MASTER_MANAGEMENT: 'マスター管理',
      PASSWORD_RESET: 'パスワード再設定',
      ITEM_MANAGEMENT: 'アイテム管理',
      CLIENTS_MANAGEMENT: 'クライアント',
      STORES: 'ジムストア (ストア)',
      DEVICES_MANAGEMENT: 'デバイス管理',
      WATCH_MANAGEMENT: '監視管理',
      CRONJOB_MANAGEMENT: 'cronジョブ管理',
      CRONJOB: 'cronジョブ',
      SYSTEM_MANAGEMENT: 'システム管理',
      COMMON_MANAGEMENT: '共通管理',
      REPORT_MANAGEMENT: 'レポート管理',
      TYPE_MANAGEMENT: 'タイプ管理',
      TABATA_SETTING_MANAGEMENT: 'システムTABATA設定管理',
    },
    CLIENT: {
      ID: 'クライアントID',
      FULLNAME: 'クライアント名',
      KANA_FULLNAME: 'カナフルネーム',
      ADDRESS: '場所',
      PHONE: '電話番号',
      ACCOUNT: 'アカウント',
      ROLE: '権限',
      EMAIL: 'メール',
      PROVINCE: '州',
      WARD: 'ワード',
      AREA: 'エリア',
      STATUS: 'ステータス',
      PASSWORD: 'パスワード',
      TYPE: 'タイプ',
      SORT_KEY: 'ソートキー',
      SORT_TYPE: 'ソート値',
      BUTTON: {
        NEW_CLIENT: '新しいクライアント',
      },
      TAG: {
        NONE: 'None',
        ACTIVE: 'アクティブ',
        DEACTIVE: '非アクティブ',
        ASC: 'Asc',
        DESC: 'Desc',
      },
      MODAL_TITLE: {
        CREATE: 'ジムのオーナーを作成する',
        UPDATE: 'ジムのオーナーを更新する',
        LIST: 'タブレットの一覧表示',
        COLUMN: 'ACTION',
        DELETE: 'ジム所有者の削除を確認する',
      },
    },
    DEVICES: {
      CURRENT_HEART_RATE: '心拍数',
      DEVICE_ID: 'タブレット　ID',
      DEVICE_LABEL: 'タブレットラベル',
      FIRMWARE_VERSION: 'ファームウェアのバージョン',
      HEART_RATE: '心拍数',
      CUSTOMER_WEIGHT: 'ユーザー体重',
      CUSTOMER_HEIGHT: 'ユーザー身長',
      CUSTOMER_AGE: 'ユーザー年齢',
      CALORIES_BURNED: 'カロリー',
      BPM_RATE: 'BPM レート',
      DEVICE_STATUS: 'タブレットステータス',
      DEVICE_VERSION: 'デバイスバージョン',
      DEVICE_TYPE: 'タブレットタイプ',
      DEVICE_UUID: 'タブレット　UUID',
      WATCH_STATUS: 'ウォッチステータス',
      WATCH_TYPE: 'ウォッチタイプ',
      WATCH_UUID: 'ウォッチ　UUID',
      WATCH_BATTERY: '時計のバッテリー',
      UPDATED_DATE: '最終変更日',
      CREATED_DATE: '作成日',
      BUTTON: {
        NEW_DEVICE: '新しいデバイス',
      },
      TAG: {
        ONLINE: 'オンライン',
        OFFLINE: 'オフライン',
        EMPTY: '空の',
      },
      MODAL_TITLE: {
        CREATE: 'タブレットの作成',
        UPDATE: 'タブレット編集',
        LIST: 'リストウォッチ',
        COLUMN: 'ACTION',
        DELETE: 'タブレットの削除を確認する',
      },
    },
    WATCH: {
      WATCH_ID: 'ウォッチID',
      WATCH_LABEL: 'ウォッチラベル',
      DEVICE_ID: 'タブレットラベル',
      FIRMWARE_VERSION: 'ファームウェア・のバージョン',
      HEART_RATE: '心拍数',
      CUSTOMER_WEIGHT: '顧客の体重',
      CUSTOMER_HEIGHT: '顧客の身長',
      CUSTOMER_AGE: '顧客の年齢',
      CALORIES_BURNED: '消費カロリー',
      BPM_RATE: 'BPM レート',
      DEVICE_STATUS: 'タブレットステータス',
      DEVICE_TYPE: 'タブレットタイプ',
      DEVICE_UUID: 'タブレット　UUID',
      WATCH_STATUS: 'ウォッチステータス',
      WATCH_TYPE: 'ウォッチタイプ',
      WATCH_UUID: 'ウォッチ UUID',
      WATCH_BATTERY: 'ウォッチ電池',
      UPDATED_DATE: '最終変更日',
      CREATED_DATE: '作成日',
      BUTTON: {
        NEW_WATCH: '新規作成',
      },
      TAG: {
        ONLINE: 'オンライン',
        OFFLINE: 'オフライン',
        EMPTY: '空の',
      },
      MODAL_TITLE: {
        CREATE: 'タブレットの作成',
        UPDATE: 'タブレット編集',
        LIST: 'リストウォッチ',
        COLUMN: 'ACTION',
        DELETE: 'タブレットの削除を確認する',
      },
    },
    WATCH_SESSION: {
      WATCH_ID: 'ウォッチID',
      SESSION_ID: 'セッションID',
      SESSION_STATUS: 'セッションステータス',
      DEVICE_ID: 'タブレットラベル',
      HEART_RATE: '心拍数',
      CUSTOMER_WEIGHT: '顧客の体重',
      CUSTOMER_HEIGHT: '顧客の身長',
      CUSTOMER_AGE: '顧客の年齢',
      CALORIES_BURNED: '消費カロリー',
      BPM_RATE: 'BPM レート',
      END_DATE: '最終変更日',
      START_DATE: '作成日',
      BUTTON: {
        NEW_WATCH: '新規作成',
      },
      TAG: {
        ONLINE: 'オンライン',
        OFFLINE: 'オフライン',
        EMPTY: '空の',
      },
    },
    SYSTEM_COMMON: {
      ID: 'ID',
      PARENT_ID: '親ID',
      KEY: '鍵',
      DESCRIPTION: '説明',
      FIRST_VALUE: '最初の値',
      FIRST_OPTION: '最初の選択肢',
      SECOND_VALUE: '顧客の身長',
      SECOND_OPTION: '2番目の値',
      STATUS: '状態',
      UPDATED_DATE: '最終変更日',
      CREATED_DATE: '作成日',
      SORT_KEY: 'ソートキー',
      SORT_TYPE: 'ソート値',
      BUTTON: {
        NEW_COMMON: '新しい共通',
      },
      TAG: {
        ONLINE: 'オンライン',
        OFFLINE: 'オフライン',
        EMPTY: '空の',
        NONE: 'None',
        ACTIVE: 'アクティブ',
        DEACTIVE: '非アクティブ',
        ASC: 'Asc',
        DESC: 'Desc',
      },
      MODAL_TITLE: {
        CREATE: 'タブレットの作成',
        UPDATE: 'タブレット編集',
        LIST: 'リストウォッチ',
        COLUMN: 'ACTION',
        DELETE: 'タブレットの削除を確認する',
      },
    },
    SYSTEM_CRONJOB: {
      ID: 'ID',
      NAME: 'cronジョブ名',
      EXECUTE_TIME: '実行時間',
      EXECUTE_DATE: '実行日',
      STATUS: '状態',
      UPDATED_DATE: '最終変更日',
      CREATED_DATE: '作成日',
      SORT_KEY: 'ソートキー',
      SORT_TYPE: 'ソート値',
      BUTTON: {
        NEW_JOB: '新しい cronjob',
      },
      TAG: {
        ONLINE: 'オンライン',
        OFFLINE: 'オフライン',
        EMPTY: '空の',
        NONE: 'None',
        ACTIVE: 'アクティブ',
        DEACTIVE: '非アクティブ',
        ASC: 'Asc',
        DESC: 'Desc',
      },
      MODAL_TITLE: {
        CREATE: 'タブレットの作成',
        UPDATE: 'タブレット編集',
        LIST: 'リストウォッチ',
        COLUMN: 'ACTION',
        DELETE: 'タブレットの削除を確認する',
      },
    },
    SYSTEM_REPORT: {
      ID: 'ID',
      TYPE: 'レポートタイプ',
      TOTAL: '合計',
      STATUS: '状態',
      UPDATED_DATE: '最終変更日',
      CREATED_DATE: '作成日',
      SORT_KEY: 'ソートキー',
      SORT_TYPE: 'ソート値',
      BUTTON: {
        NEW_REPORT: '新しいレポート',
      },
      TAG: {
        ONLINE: 'オンライン',
        OFFLINE: 'オフライン',
        EMPTY: '空の',
        NONE: 'None',
        ACTIVE: 'アクティブ',
        DEACTIVE: '非アクティブ',
        ASC: 'Asc',
        DESC: 'Desc',
      },
      MODAL_TITLE: {
        CREATE: 'タブレットの作成',
        UPDATE: 'タブレット編集',
        LIST: 'リストウォッチ',
        COLUMN: 'ACTION',
        DELETE: 'タブレットの削除を確認する',
      },
    },
    SYSTEM_TYPE: {
      ID: 'ID',
      TYPE_NAME: 'タイプ名',
      TYPE_HYPOXIC: '低酸素型',
      STATUS: '状態',
      UPDATED_DATE: '最終変更日',
      CREATED_DATE: '作成日',
      SORT_KEY: 'ソートキー',
      SORT_TYPE: 'ソート値',
      BUTTON: {
        NEW_TYPE: '新しいタイプ',
      },
      TAG: {
        ONLINE: 'オンライン',
        OFFLINE: 'オフライン',
        EMPTY: '空の',
        NONE: 'None',
        ACTIVE: 'アクティブ',
        DEACTIVE: '非アクティブ',
        ASC: 'Asc',
        DESC: 'Desc',
      },
      MODAL_TITLE: {
        CREATE: 'タブレットの作成',
        UPDATE: 'タブレット編集',
        LIST: 'リストウォッチ',
        COLUMN: 'ACTION',
        DELETE: 'タブレットの削除を確認する',
      },
    },
    SYSTEM_TABATA_SETTING: {
      ID: 'ID',
      ROUND: 'ラウンド',
      DURATION: '間隔',
      STATUS: '状態',
      RESTING_DURATION: '休息期間',
      UPDATED_DATE: '最終変更日',
      CREATED_DATE: '作成日',
      SORT_KEY: 'ソートキー',
      SORT_TYPE: 'ソート値',
      BUTTON: {
        NEW_SETTING: '新しいタイプ',
      },
      TAG: {
        ONLINE: 'オンライン',
        OFFLINE: 'オフライン',
        EMPTY: '空の',
        NONE: 'None',
        ACTIVE: 'アクティブ',
        DEACTIVE: '非アクティブ',
        ASC: 'Asc',
        DESC: 'Desc',
      },
      MODAL_TITLE: {
        CREATE: 'タブレットの作成',
        UPDATE: 'タブレット編集',
        LIST: 'リストウォッチ',
        COLUMN: 'ACTION',
        DELETE: 'タブレットの削除を確認する',
      },
    },
    ERROR: {
      TITLE: {
        OOPS: 'Oops!',
        NOT_FOUND: 'そのページは見つかりません。',
        SYSTEM_ERROR: 'システムエラー',
        SOMETHING_ERROR: 'エラーが発生しました。しばらくしてからもう一度お試しください。',
      },
      BUTTON: {
        RETURN_HOME: '帰国',
      }
    },
    AUTH: {
      RESET_PASSWORD: {
        CURRENT_PASSWORD: '現在のパスワード',
        PASSWORD: '新規パスワード',
        CONFIRM_PASSWORD: '新規パスワード（確認）',
        TITLE: {
          RESET_PASSWORD: 'パスワードを再設定する',
          SENT_PASSWORD: 'パスワードが変更されました。',
        },
        BUTTON: {
          SUBMIT: '更新する',
          LOGIN: 'サインイン',
        },
      },

      CHANGE_PASSWORD: {
        CURRENT_PASSWORD: '現在のパスワード',
        PASSWORD: '新規パスワード',
        CONFIRM_PASSWORD: '新規パスワード（確認）',
        BUTTON: {
          SUBMIT: '更新する',
          YAGO_MANAGEMENT: 'メニュー',
        },
        TITLE: {
          CHANGE_PASSWORD: 'パスワードを変更する',
          SENT_PASSWORD: 'パスワードが変更されました',
        },
      },
      FORGOT_PASSWORD: {
        ID: 'ID',
        TITLE: {
          FORGOT_PASSWORD: 'パスワードをお忘れですか',
          SENT_PASSWORD:
            'ご登録のメールアドレスにパスワード再設定用URLが送信されました。',
        },
        BUTTON: {
          SEND: '送信',
          CANCEL: 'キャンセル',
        },
      },
      GENERAL: {
        OR: 'または',
        SUBMIT_BUTTON: '提出する',
        NO_ACCOUNT: 'アカウントを持っていない？',
        SIGNUP_BUTTON: 'サインアップ',
        FORGOT_BUTTON: 'パスワードをお忘れですか',
        BACK_BUTTON: 'バック',
        PRIVACY: 'プライバシー',
        LEGAL: '法的',
        CONTACT: '接触',
      },
      LOGIN: {
        ID: 'ID',
        EMAIL: 'EMAIL',
        PASSWORD: 'パスワード',
        TITLE: {
          SIGN_IN: 'サインイン',
          FORGOT_PASSWORD: 'パスワードを忘れた方はこちら?',
        },
        BUTTON: {
          SIGN_IN: 'サインイン',
        },
      },
      FORGOT: {
        TITLE: 'Forgotten Password?',
        DESC: 'Enter your email to reset your password',
        SUCCESS: 'Your account has been successfully reset.',
      },
      REGISTER: {
        TITLE: 'Sign Up',
        DESC: 'Enter your details to create your account',
        SUCCESS: 'Your account has been successfuly registered.',
      },
      INPUT: {
        EMAIL: 'Email',
        FULLNAME: 'Fullname',
        PASSWORD: 'Password',
        CONFIRM_PASSWORD: 'Confirm Password',
        USERNAME: 'ユーザー名',
      },
      VALIDATION: {
        INVALID: '{{name}} is not valid',
        REQUIRED: '{{name}} is required',
        MIN_LENGTH: '{{name}} minimum length is {{min}}',
        AGREEMENT_REQUIRED: 'Accepting terms & conditions are required',
        NOT_FOUND: 'The requested {{name}} is not found',
        INVALID_LOGIN: 'The login detail is incorrect',
        REQUIRED_FIELD: 'Required field',
        MIN_LENGTH_FIELD: 'Minimum field length:',
        MAX_LENGTH_FIELD: 'Maximum field length:',
        INVALID_FIELD: 'Field is not valid',
      },
    },
    ECOMMERCE: {
      COMMON: {
        SELECTED_RECORDS_COUNT: 'Selected records count: ',
        ALL: 'All',
        SUSPENDED: 'Suspended',
        ACTIVE: 'Active',
        FILTER: 'Filter',
        BY_STATUS: 'by Status',
        BY_TYPE: 'by Type',
        BUSINESS: 'Business',
        INDIVIDUAL: 'Individual',
        SEARCH: 'Search',
        IN_ALL_FIELDS: 'in all fields',
      },
      ECOMMERCE: 'eCommerce',
      CUSTOMERS: {
        CUSTOMERS: 'Customers',
        CUSTOMERS_LIST: 'Customers list',
        NEW_CUSTOMER: 'New Customer',
        DELETE_CUSTOMER_SIMPLE: {
          TITLE: 'Customer Delete',
          DESCRIPTION: 'Are you sure to permanently delete this customer?',
          WAIT_DESCRIPTION: 'Customer is deleting...',
          MESSAGE: 'Customer has been deleted',
        },
        DELETE_CUSTOMER_MULTY: {
          TITLE: 'Customers Delete',
          DESCRIPTION: 'Are you sure to permanently delete selected customers?',
          WAIT_DESCRIPTION: 'Customers are deleting...',
          MESSAGE: 'Selected customers have been deleted',
        },
        UPDATE_STATUS: {
          TITLE: 'Status has been updated for selected customers',
          MESSAGE: 'Selected customers status have successfully been updated',
        },
        EDIT: {
          UPDATE_MESSAGE: 'Customer has been updated',
          ADD_MESSAGE: 'Customer has been created',
        },
      },
    },
  },
};
