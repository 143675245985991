// Vietnam
export const locale = {
  lang: 'vi',
  data: {
    LANGUAGES: 'Ngôn ngữ',
    COMMON: {
      BUTTON: {
        ACTION: 'Hoạt động',
        SEARCH: 'Tìm kiếm',
        WAIT: 'Đang tìm kiếm...',
        DELETE: 'Xóa bỏ',
        HIRE: 'Tuyển dụng',
        EXPORT: 'Đầu ra',
        CLOSE: 'Hủy bỏ',
        CONTINUE: 'Tiếp tục',
        SUBMIT: 'Gửi',
      },
    },
    TRANSLATOR: {
      SELECT: 'Vui lòng chọn ngôn ngữ bạn sử dụng',
    },
    MENU: {
      NEW: 'Mới',
      ACTIONS: 'Hoạt động',
      CREATE_POST: 'Tạo bài đăng mới',
      PAGES: 'Trang',
      FEATURES: 'Đặc trưng',
      APPS: 'ứng dụng',
      DASHBOARD: 'Bảng điều khiển',
      LAYOUT_BUILDER: 'Người xây dựng bố cục',
      MATCHING: 'Khớp',
      HYAKKATEN_MANAGEMENT: 'Quản lý cửa hàng bách hóa',
      SAIJI_MANAGEMENT: 'Chi tiết sự kiện',
      YAGO_MANAGEMENT: 'Quản lý sự kiện',
      CATEGORY_MANAGEMENT: 'Quản lý danh mục',
      TAG_MANAGEMENT: 'Quản lý thẻ',
      MASTER_MANAGEMENT: 'Quản lý tổng thể',
      PASSWORD_RESET: 'Đặt lại mật khẩu',
      ITEM_MANAGEMENT: 'Quản lý mặt hàng',
      CLIENTS: 'Khách hàng',
      STORES: 'Cửa hàng tập thể dục (Cửa hàng)',
      DEVICES: 'Viên thuốc',
      WATCH: 'Cái đồng hồ',
      TITLE: {
        MASTER_MANAGEMENT: 'Quản lý tổng thể',
        PASSWORD_RESET: 'Đặt lại mật khẩu',
        ITEM_MANAGEMENT: 'Quản lý mặt hàng',
        DEVICES_MANAGEMENT: 'Quản lý thiết bị',
        WATCH_MANAGEMENT: 'Quản lý giám sát',
      },
    },
    CLIENT: {
      ID: 'ID khách hàng',
      FULLNAME: 'Tên khách hàng',
      KANA_FULLNAME: 'Tên đầy đủ của Kana',
      ADDRESS: 'địa điểm',
      PHONE: 'Số điện thoại',
      ACCOUNT: 'Tài khoản',
      ROLE: 'Thẩm quyền',
      EMAIL: 'E-mail',
      PROVINCE: 'Tình trạng',
      WARD: 'Từ',
      AREA: 'khu vực',
      STATUS: 'Trạng thái',
      PASSWORD: 'Mật khẩu',
      TYPE: 'Kiểu',
      BUTTON: {
        NEW_CLIENT: 'Khách hàng mới',
      },
      TAG: {
        NONE: 'None',
        ACTIVE: 'Hoạt động',
        DEACTIVE: 'không hoạt động',
      },
    },
    DEVICES: {
      CURRENT_HEART_RATE: 'Nhịp tim',
      DEVICE_ID: 'Mã máy tính bảng',
      DEVICE_LABEL: 'Nhãn máy tính bảng',
      FIRMWARE_VERSION: 'Phiên bản phần mềm',
      HEART_RATE: 'Nhịp tim',
      CUSTOMER_WEIGHT: 'Trọng lượng người dùng',
      CUSTOMER_HEIGHT: 'Chiều cao người dùng',
      CUSTOMER_AGE: 'Tuổi người dùng',
      CALORIES_BURNED: 'Calo',
      BPM_RATE: 'Tốc độ BPM',
      DEVICE_STATUS: 'Trạng thái máy tính bảng',
      DEVICE_TYPE: 'Loại máy tính bảng',
      DEVICE_UUID: 'UUID máy tính bảng',
      WATCH_STATUS: 'Trạng thái xem',
      WATCH_TYPE: 'Loại đồng hồ',
      WATCH_UUID: 'Xem UUID',
      WATCH_BATTERY: 'Pin đồng hồ',
      LAST_MODIFIED_DATE: 'Ngày sửa đổi lần cuối',
      CREATED_DATE: 'Ngày tạo',
      BUTTON: {
        NEW_DEVICE: 'Thiết bị mới',
      },
      TAG: {
        ONLINE: 'Trực tuyến',
        OFFLINE: 'Ngoại tuyến',
        EMPTY: 'Trống',
      },
    },
    WATCH: {
      WATCH_ID: 'NHẬN DẠNG',
      WATCH_LABEL: 'nhãn đồng hồ',
      DEVICE_ID: 'nhãn máy tính bảng',
      FIRMWARE_VERSION: 'Phiên bản phần mềm',
      HEART_RATE: 'nhịp tim',
      CUSTOMER_WEIGHT: 'trọng lượng khách hàng',
      CUSTOMER_HEIGHT: 'chiều cao của khách hàng',
      CUSTOMER_AGE: 'tuổi khách hàng',
      CALORIES_BURNED: 'lượng calo tiêu thụ',
      BPM_RATE: 'tốc độ BPM',
      DEVICE_STATUS: 'trạng thái máy tính bảng',
      DEVICE_TYPE: 'loại máy tính bảng',
      DEVICE_UUID: 'UUID máy tính bảng',
      WATCH_STATUS: 'trạng thái xem',
      WATCH_TYPE: 'loại đồng hồ',
      WATCH_UUID: 'Xem UUID',
      WATCH_BATTERY: 'đồng hồ pin',
      LAST_MODIFIED_DATE: 'Ngày sửa đổi lần cuối',
      CREATED_DATE: 'Ngày tạo',
      BUTTON: {
        NEW_WATCH: 'Tạo mới',
      },
      TAG: {
        ONLINE: 'trực tuyến',
        OFFLINE: 'ngoại tuyến',
        EMPTY: 'trống',
      },
    },
    WATCH_SESSION: {
      WATCH_ID: 'NHẬN DẠNG',
      SESSION_ID: 'ID phiên',
      SESSION_STATUS: 'trạng thái phiên',
      DEVICE_ID: 'nhãn máy tính bảng',
      HEART_RATE: 'nhịp tim',
      CUSTOMER_WEIGHT: 'trọng lượng khách hàng',
      CUSTOMER_HEIGHT: 'chiều cao của khách hàng',
      CUSTOMER_AGE: 'tuổi khách hàng',
      CALORIES_BURNED: 'lượng calo tiêu thụ',
      BPM_RATE: 'tốc độ BPM',
      END_DATE: 'Ngày sửa đổi lần cuối',
      START_DATE: 'Ngày tạo',
      BUTTON: {
        NEW_WATCH: 'Tạo mới',
      },
      TAG: {
        ONLINE: 'trực tuyến',
        OFFLINE: 'ngoại tuyến',
        EMPTY: 'trống',
      },
    },
    AUTH: {
      RESET_PASSWORD: {
        CURRENT_PASSWORD: '現在のパスワード',
        PASSWORD: '新規パスワード',
        CONFIRM_PASSWORD: '新規パスワード（確認）',
        TITLE: {
          RESET_PASSWORD: 'パスワードを再設定する',
          SENT_PASSWORD: 'パスワードが変更されました。',
        },
        BUTTON: {
          SUBMIT: '更新する',
          LOGIN: 'サインイン',
        },
      },
      CHANGE_PASSWORD: {
        CURRENT_PASSWORD: '現在のパスワード',
        PASSWORD: '新規パスワード',
        CONFIRM_PASSWORD: '新規パスワード（確認）',
        BUTTON: {
          SUBMIT: '更新する',
          YAGO_MANAGEMENT: 'メニュー',
        },
        TITLE: {
          CHANGE_PASSWORD: 'パスワードを変更する',
          SENT_PASSWORD: 'パスワードが変更されました',
        },
      },
      FORGOT_PASSWORD: {
        ID: 'NHẬN DẠNG',
        TITLE: {
          FORGOT_PASSWORD: 'パスワードをお忘れですか',
          SENT_PASSWORD:
            'ご登録のメールアドレスにパスロ設定用URLが送信されました。',
        },
        BUTTON: {
          SEND: '送信',
          CANCEL: 'キャンセル',
        },
      },
      GENERAL: {
        OR: 'または',
        SUBMIT_BUTTON: '提出する',
        NO_ACCOUNT: 'アカウントを持っていない？',
        SIGNUP_BUTTON: 'サインアップ',
        FORGOT_BUTTON: 'パスワードをお忘れですか',
        BACK_BUTTON: 'バック',
        PRIVACY: 'プライバシー',
        LEGAL: '法的',
        CONTACT: '接触',
      },
      LOGIN: {
        ID: 'NHẬN DẠNG',
        EMAIL: 'E-MAIL',
        PASSWORD: 'パスワード',
        TITLE: {
          SIGN_IN: 'サインイン',
          FORGOT_PASSWORD: 'Bạn có biết không?',
        },
        BUTTON: {
          SIGN_IN: 'サインイン',
        },
      },
      FORGOT: {
        TITLE: 'Quên mật khẩu?',
        DESC: 'Nhập email của bạn để đặt lại mật khẩu',
        SUCCESS: 'Tài khoản của bạn đã được thiết lập lại thành công.',
      },
      REGISTER: {
        TITLE: 'Đăng ký',
        DESC: 'Nhập thông tin chi tiết của bạn để tạo tài khoản',
        SUCCESS: 'Tài khoản của bạn đã được đăng ký thành công.',
      },
      INPUT: {
        EMAIL: 'E-mail',
        FULLNAME: 'Họ và tên',
        PASSWORD: 'Mật khẩu',
        CONFIRM_PASSWORD: 'Xác nhận mật khẩu',
        USERNAME: 'ユーザー名',
      },
      VALIDATION: {
        INVALID: '{{name}} không hợp lệ',
        REQUIRED: '{{name}} là bắt buộc',
        MIN_LENGTH: '{{name}} chiều dài tối thiểu là {{min}}',
        AGREEMENT_REQUIRED: 'Chấp nhận các điều khoản và điều kiện là bắt buộc',
        NOT_FOUND: 'Người được yêu cầu {{name}} không được tìm thấy',
        INVALID_LOGIN: 'Chi tiết đăng nhập không chính xác',
        REQUIRED_FIELD: 'Trường bắt buộc',
        MIN_LENGTH_FIELD: 'Độ dài trường tối thiểu:',
        MAX_LENGTH_FIELD: 'Độ dài trường tối đa:',
        INVALID_FIELD: 'Trường không hợp lệ',
      },
    },
    ECOMMERCE: {
      COMMON: {
        SELECTED_RECORDS_COUNT: 'Số lượng bản ghi đã chọn: ',
        ALL: 'Tất cả',
        SUSPENDED: 'Cấm',
        ACTIVE: 'Tích cực',
        FILTER: 'Lọc',
        BY_STATUS: 'theo trạng thái',
        BY_TYPE: 'theo loại',
        BUSINESS: 'Việc kinh doanh',
        INDIVIDUAL: 'Cá nhân',
        SEARCH: 'Tìm kiếm',
        IN_ALL_FIELDS: 'trong mọi lĩnh vực',
      },
      ECOMMERCE: 'Thương mại điện tử',
      CUSTOMERS: {
        CUSTOMERS: 'Khách hàng',
        CUSTOMERS_LIST: 'Danh sách khách hàng',
        NEW_CUSTOMER: 'Khách hàng mới',
        DELETE_CUSTOMER_SIMPLE: {
          TITLE: 'Xóa khách hàng',
          DESCRIPTION: 'Bạn có chắc chắn xóa vĩnh viễn khách hàng này không?',
          WAIT_DESCRIPTION: 'Khách hàng đang xóa...',
          MESSAGE: 'Khách hàng đã bị xóa',
        },
        DELETE_CUSTOMER_MULTY: {
          TITLE: 'Khách hàng Xóa',
          DESCRIPTION:
            'Bạn có chắc chắn xóa vĩnh viễn các khách hàng đã chọn không?',
          WAIT_DESCRIPTION: 'Khách hàng đang xóa...',
          MESSAGE: 'Khách hàng được chọn đã bị xóa',
        },
        UPDATE_STATUS: {
          TITLE: 'Trạng thái đã được cập nhật cho khách hàng được chọn',
          MESSAGE:
            'Trạng thái khách hàng được chọn đã được cập nhật thành công',
        },
        EDIT: {
          UPDATE_MESSAGE: 'Khách hàng đã được cập nhật',
          ADD_MESSAGE: 'Khách hàng đã được tạo',
        },
      },
    },
  },
};
